export const calcalator = (data) => {
    // DATOS
    // Nombre del trabajador:
    let DATOS_F10 = data.name;

    // sueldo mensul es
    let salario_mensual = data.salario_mensual;

    // Ultimo Sueldo Diario  $
    let DATOS_E12 = salario_mensual / 30;
    // console.log("El salario diario es de ", DATOS_E12);

    // -----------------------------------------
    // Cálculo;

    let type_de_pago = 1;

    let DATOS_E22 = 0;
    let DATOS_G22 = 0;
    let DATOS_I22 = 0;
    let DATOS_K22 = 0;

    // ------------------------------------

    // Datos del Cálculo

    // Terminación de la relación del trabajo por:
    let DATOS_A27 = data.motivo;

    // Fecha de Ingreso al Trabajo
    const date_ingreso = data.date_ingreso;

    const date_ingreso_array = date_ingreso.split("-");

    // dia
    let DATOS_A28 = date_ingreso_array[2];
    //   mes
    let DATOS_A29 = date_ingreso_array[1];
    //   año
    let DATOS_A32 = date_ingreso_array[0];

    // Fecha de Baja o Cálculo
    const date_egreso = data.date_egreso;

    const date_egreso_array = date_egreso.split("-");

    let DATOS_B28 = date_egreso_array[2];
    //   mes
    let DATOS_B29 = date_egreso_array[1];
    //   año
    let DATOS_B32 = date_egreso_array[0];

    // // Salario Diario Nominal
    let DATOS_K36 = DATOS_E12;

    // // Salario Diario Integrado
    let DATOS_K34 = DATOS_K36;

    // // Salario Mínimo de Área Geográfica D39
    // // 1 A, 2 B, 3 C
    let DATOS_B40 = data.state;
    // console.log("En que estado te encuentras ", DATOS_B40);

    // // Salarios Mínnimos
    // console.log(DATOS_B40);
    let DATOS_L40 = 260.34;
    let DATOS_L42 = 172.87;
    let DATOS_L44 = 0;

    // console.log("zona a", DATOS_L40);
    // console.log("zona b", DATOS_L42);

    // // % de Prima Vacacional Correspondiente
    let DATOS_I42 = 25;

    // // Días de Aguinaldo del ejercicio
    let DATOS_I44 = 15;

    // // Para Aguinaldo: Faltas o incapacidades en el ejercicio
    let DATOS_I46 = 0;

    // // Pendientes de pagar: Días de Vacaciones/periodo
    let DATOS_I48 = data.dias_vacaciones;
    // DATOS_K58
    const DATOS_K48 = data.perido_inicio_v;
    const DATOS_K58 = data.perido_final_v;
    // // Pendientes de pagar: Días de Prima Vacacional/periodo
    let DATOS_I50 = data.dias_prima_v;
    // const DATOS_K48 = "2017-2018";
    const DATOS_K50 = data.periodo_inicio_p_v;
    const DATOS_K60 = data.periodo_final_p_v;
    // // -------------------------------------------------------
    // // Otras precepciones por pagar (Capturar conceptos e importes)
    let DATOS_D54 = "Otras Prestaciones Exentas";
    let DATOS_G54 = 0;

    let DATOS_D56 = "Gratificiación (pago extra)";
    let DATOS_G56 = 0;

    // // Días de Salario por Pagar
    let DATOS_L54 = 0;

    // // -------------CALCULOS ------------------

    // Sueldo Diario:
    let G3 = DATOS_K36;
    // console.log("Sueldo Diario", G3);
    let K3 = DATOS_K34;
    // Sueldo Integrado al IMSS:
    // console.log("Sueldo Integrado al IMSS:", DATOS_K34);
    // Resultado de la antigüedad

    // Fecha de Ingreso:
    let D13 = new Date(`${DATOS_A32}-${DATOS_A29}-${DATOS_A28}`);
    // console.log("Fecha de Ingreso:", D13);

    // Fecha del Finiquito:
    let H13 = new Date(`${DATOS_B32}-${DATOS_B29}-${DATOS_B28}`);
    // console.log("Fecha del Finiquito:", H13);

    // Prima Vac:
    let K13 = DATOS_I42 / 100;
    // console.log("Prima Vac:", K13);

    // Total de días Laborados:

    const date1 = new Date(`${DATOS_A32}-${DATOS_A29}-${DATOS_A28}`),
        date2 = new Date(`${DATOS_B32}-${DATOS_B29}-${DATOS_B28}`);

    const difference = (date2, date1) => {
        const date1utc = Date.UTC(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate()
        );
        const date2utc = Date.UTC(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate()
        );
        let day = 1000 * 60 * 60 * 24;
        return (date2utc - date1utc) / day;
    };

    let F14 = parseFloat(difference(date2, date1)) + parseFloat(1);
    // console.log("Total de días Laborados:", F14);

    // Días con derecho al aguinaldo:

    let F15 = 0;

    if (DATOS_B32 === DATOS_A32) {
        F15 = difference(date2, date1) - DATOS_I46;
    } else {
        F15 = difference(date2, new Date(`${DATOS_B32}-01-01`));
    }

    F15 = parseFloat(F15 - DATOS_I46) + parseFloat(1);

    // console.log("Días con derecho al aguinaldo:", F15);

    // Días con derecho a Vacaciones:

    let F16 = 0;
    if (DATOS_B32 == DATOS_A32) {
        F16 = F14;
    } else {
        if (H13 >= new Date(`${DATOS_B32}-${DATOS_A29}-${DATOS_A28}`)) {
            F16 = difference(H13, new Date(`${DATOS_B32}-${DATOS_A29}-${DATOS_A28}`));
            F16++;
        } else {
            F16 = difference(
                H13,
                new Date(`${DATOS_B32 - 1}-${DATOS_A29}-${DATOS_A28}`)
            );
            F16++;
        }
    }

    // console.log("Días con derecho a Vacaciones:", F16);

    // Días para Prima Vacacional
    let F17 = F16;
    // console.log("Días para Prima Vacacional", F16);

    // Años Cumplidos para LFT:
    // 772
    let years = Math.floor(F14 / 365);
    let months = (365 % F14) / 30;
    // console.log("Años Cumplidos para LFT", years);
    // console.log(months);

    //   //  =CONCATENAR(
    //   //     TEXTO(
    //   //         TRUNCAR(
    //   //             F14 / 365;
    //   //             0
    //   //         );
    //   //         0
    //   //     );
    //   //     " años, ";
    //   //     TEXTO(
    //   //         TRUNCAR(
    //   //             RESIDUO(
    //   //                 F14;
    //   //                 365
    //   //             ) / 30,4;
    //   //             0
    //   //         );
    //   //         "0"
    //   //     );
    //   //     " meses"
    //   // )

    // Años Cumplidos para ISR(art 109):
    let K15 = Math.round(F14 / 365);
    // console.log("Años Cumplidos para ISR(art 109): ", K15);

    let H16 =
        DATOS_I48 !== ""
            ? "Vacacaciones Pend.: " + `${DATOS_K48} - ${DATOS_K58}`
            : "";
    let K16 = DATOS_I48;
    // console.log(H16, K16);

    let H17 =
        DATOS_I50 !== ""
            ? "Prima Vacacio. Pend: " + `${DATOS_K50} - ${DATOS_K60}`
            : "";
    let K17 = DATOS_I50;
    // console.log(H17, K17);

    // -------------------------------------------------------------
    // CALCULO DE PAGOS Y RETENCIONES POR  FINIQUITOS

    // console.log(
    // "************* CALCULO DE PAGOS Y RETENCIONES POR  FINIQUITOS ***********"
    // );
    // Salario Mínimo del Área Geográfica

    let F19 = 0;

    if (DATOS_B40 == 1) {
        F19 = DATOS_L40;
    } else {
        if (DATOS_B40 == 2) {
            F19 = DATOS_L42;
        } else {
            F19 = DATOS_L44;
        }
    }

    // console.log("Salario Mínimo del Área Geográfica", F19);

    // Cálculo del Finiquito

    // Salarios Pendientes
    // Días
    let E21 = DATOS_L54;
    // console.log("Salarios Pendientes - dias", E21);

    // Importe
    let F21 = E21 * G3;
    // console.log("Salarios Pendientes - importe", F21);

    // Vacaciones:
    // Días
    const parametros_vacacionnes = [
        {de: 0, a: 1, d_p: 6, d_v: 6, lft: 1},
        {de: 1, a: 2, d_p: 8, d_v: 8, lft: 2},
        {
            de: 2,
            a: 3,
            d_p: 10,
            d_v: 10,
            lft: 3,
        },
        {
            de: 3,
            a: 4,
            d_p: 12,
            d_v: 12,
            lft: 4,
        },
        {
            de: 4,
            a: 5,
            d_p: 14,
            d_v: 14,
            lft: 5,
        },
        {
            de: 5,
            a: 6,
            d_p: 14,
            d_v: 14,
            lft: 6,
        },
        {
            de: 6,
            a: 7,
            d_p: 14,
            d_v: 14,
            lft: 7,
        },
        {
            de: 7,
            a: 8,
            d_p: 14,
            d_v: 14,
            lft: 8,
        },
        {
            de: 8,
            a: 9,
            d_p: 14,
            d_v: 14,
            lft: 9,
        },
        {
            de: 9,
            a: 10,
            d_p: 16,
            d_v: 16,
            lft: 10,
        },
        {
            de: 10,
            a: 11,
            d_p: 16,
            d_v: 16,
            lft: 11,
        },
        {
            de: 11,
            a: 12,
            d_p: 16,
            d_v: 16,
            lft: 12,
        },
        {
            de: 12,
            a: 13,
            d_p: 16,
            d_v: 16,
            lft: 13,
        },
        {
            de: 13,
            a: 14,
            d_p: 16,
            d_v: 16,
            lft: 14,
        },
        {
            de: 14,
            a: 15,
            d_p: 18,
            d_v: 18,
            lft: 15,
        },
        {
            de: 15,
            a: 16,
            d_p: 18,
            d_v: 18,
            lft: 16,
        },
        {
            de: 16,
            a: 17,
            d_p: 18,
            d_v: 18,
            lft: 17,
        },
        {
            de: 17,
            a: 18,
            d_p: 18,
            d_v: 18,
            lft: 18,
        },
        {
            de: 18,
            a: 19,
            d_p: 18,
            d_v: 18,
            lft: 19,
        },
        {
            de: 19,
            a: 20,
            d_p: 20,
            d_v: 20,
            lft: 20,
        },
        {
            de: 20,
            a: 21,
            d_p: 20,
            d_v: 20,
            lft: 21,
        },
        {
            de: 21,
            a: 22,
            d_p: 20,
            d_v: 20,
            lft: 22,
        },
        {
            de: 22,
            a: 23,
            d_p: 20,
            d_v: 20,
            lft: 23,
        },
        {
            de: 23,
            a: 24,
            d_p: 20,
            d_v: 20,
            lft: 24,
        },
        {
            de: 24,
            a: 25,
            d_p: 22,
            d_v: 22,
            lft: 25,
        },
        {
            de: 25,
            a: 26,
            d_p: 22,
            d_v: 22,
            lft: 26,
        },
        {
            de: 26,
            a: 27,
            d_p: 22,
            d_v: 22,
            lft: 27,
        },
        {
            de: 27,
            a: 28,
            d_p: 22,
            d_v: 22,
            lft: 28,
        },
        {
            de: 28,
            a: 29,
            d_p: 22,
            d_v: 22,
            lft: 29,
        },
        {
            de: 29,
            a: 30,
            d_p: 24,
            d_v: 24,
            lft: 30,
        },
        {
            de: 30,
            a: 31,
            d_p: 24,
            d_v: 24,
            lft: 31,
        },
        {
            de: 31,
            a: 32,
            d_p: 24,
            d_v: 24,
            lft: 32,
        },
        {
            de: 32,
            a: 33,
            d_p: 24,
            d_v: 24,
            lft: 33,
        },
        {
            de: 33,
            a: 34,
            d_p: 24,
            d_v: 24,
            lft: 34,
        },
        {
            de: 34,
            a: 35,
            d_p: 26,
            d_v: 26,
            lft: 35,
        },
        {
            de: 35,
            a: 36,
            d_p: 26,
            d_v: 26,
            lft: 36,
        },
        {
            de: 36,
            a: 37,
            d_p: 26,
            d_v: 26,
            lft: 37,
        },
        {
            de: 37,
            a: 38,
            d_p: 26,
            d_v: 26,
            lft: 38,
        },
        {
            de: 38,
            a: 39,
            d_p: 26,
            d_v: 26,
            lft: 39,
        },
        {
            de: 39,
            a: 40,
            d_p: 28,
            d_v: 28,
            lft: 40,
        },
        {
            de: 40,
            a: 41,
            d_p: 28,
            d_v: 28,
            lft: 41,
        },
        {
            de: 41,
            a: 42,
            d_p: 28,
            d_v: 28,
            lft: 42,
        },
        {
            de: 42,
            a: 43,
            d_p: 28,
            d_v: 28,
            lft: 43,
        },
        {
            de: 43,
            a: 44,
            d_p: 28,
            d_v: 28,
            lft: 44,
        },
        {
            de: 44,
            a: 45,
            d_p: 30,
            d_v: 30,
            lft: 45,
        },
        {
            de: 45,
            a: 46,
            d_p: 30,
            d_v: 30,
            lft: 46,
        },
        {
            de: 46,
            a: 47,
            d_p: 30,
            d_v: 30,
            lft: 47,
        },
        {
            de: 47,
            a: 48,
            d_p: 30,
            d_v: 30,
            lft: 48,
        },
        {
            de: 48,
            a: 49,
            d_p: 30,
            d_v: 30,
            lft: 49,
        },
        {
            de: 49,
            a: 50,
            d_p: 32,
            d_v: 32,
            lft: 50,
        },
        {
            de: 50,
            a: 51,
            d_p: 32,
            d_v: 32,
            lft: 51,
        },
        {
            de: 51,
            a: 52,
            d_p: 32,
            d_v: 32,
            lft: 52,
        },
        {
            de: 52,
            a: 53,
            d_p: 32,
            d_v: 32,
            lft: 53,
        },
        {
            de: 53,
            a: 54,
            d_p: 32,
            d_v: 32,
            lft: 54,
        },
    ];

    let E22 = 0;
    let query = Math.trunc(F14 / 365);
    // 2

    const vacaciones = parametros_vacacionnes
        .map((item) => item.de)
        .sort((a, b) => a - b);

    let ultimo_vacacion = 0;
    for (let index = 0; index < vacaciones.length; index++) {
        const element = vacaciones[index];
        // console.log(element);

        if (element > query) {
            ultimo_vacacion = vacaciones[index - 1];
            break;
        } else {
            ultimo_vacacion = element;
        }
    }

    let find = parametros_vacacionnes.find((item) => item.de === ultimo_vacacion);

    // console.log(find);

    if (DATOS_B32 === DATOS_A32) {
        E22 = (find.d_v * F16) / 365;
    } else {
        E22 = parseFloat(K16) + parseFloat(find.d_v * F16) / 365;
    }
    // console.log("Vacaciones - dias", E22);

    // Importe
    let F22 = E22 * G3;
    // console.log("Vacaciones - importe", F22);

    // Aguinaldo:
    // Días
    let E23 = (F15 * DATOS_I44) / 365;
    // console.log("Aguinaldo - dias", E23);

    // Importe
    let F23 = E23 * DATOS_K36;
    // console.log("Aguinaldo - importe", F23);

    // Prima Vacacional:
    // Días
    let E24 = 0;
    if (DATOS_B32 === DATOS_A32) {
        E24 = (find.d_p * F17) / 365;
    } else {
        E24 = parseFloat(K17) + parseFloat(find.d_p * F17) / 365;
    }
    // console.log("Prima Vacacional - dias", E24);

    // Importe
    let F24 = E24 * G3 * K13;
    // console.log("Prima Vacacional - importe", F24);

    let C25 = DATOS_D54;
    let E25 = "N/A";
    // console.log(C25 + " - dias:", E25);

    let F25 = DATOS_G54;

    // console.log(C25 + " - importe:", F25);

    // importe total
    let F26 =
        parseFloat(F21) +
        parseFloat(F22) +
        parseFloat(F23) +
        parseFloat(F24) +
        parseFloat(F25);
    // console.log("Importe total:", F26);

    // Salario Mínimo del D.F.:
    let K19 = DATOS_L40;
    if (DATOS_B40 == 1) {
        K19 = 260.34;
    } else {
        K19 = 172.87;
    }
    // console.log("Salario Mínimo del D.F", K19);

    // Exentos

    let I21 = 0;
    let I22 = 0;
    let I23 = F23 < F19 * 30 ? F23 : F19 * 30;
    // console.log(I23);

    let I24 = F24 < F19 * 15 ? F24 : F19 * 15;
    // console.log(I24);

    let I25 = F25;
    // console.log(F25);

    // total exentos
    let I26 =
        parseFloat(I21) +
        parseFloat(I22) +
        parseFloat(I23) +
        parseFloat(I24) +
        parseFloat(I25);
    // console.log("Total Exentos", I26);

    // Gravados
    let J21 = F21;
    let J22 = F22;
    let J23 = F23 - I23;
    let J24 = F24 - I24;
    let J25 = F25 - I25;

    // total gravados
    let J26 = F26 - I26;
    // console.log("Total gravados: ", J26);

    // Aplicación de la tarifa mensual al finiquito
    // Finiquito Gravado
    let G30 = J26;
    // console.log("Finiquito Gravado", G30);

    const tarifas = [
        {
            lim_inf: 0.01,
            lim_sup: 496.07,
            couta_fija: 0.0,
            sobre_exc: 1.92 / 100,
        },
        {
            lim_inf: 496.08,
            lim_sup: 4210.41,
            couta_fija: 9.52,
            sobre_exc: 6.4 / 100,
        },
        {
            lim_inf: 4210.42,
            lim_sup: 7399.42,
            couta_fija: 247.23,
            sobre_exc: 10.88 / 100,
        },
        {
            lim_inf: 7399.43,
            lim_sup: 8601.5,
            couta_fija: 594.24,
            sobre_exc: 16.0 / 100,
        },
        {
            lim_inf: 8601.51,
            lim_sup: 10298.35,
            couta_fija: 786.55,
            sobre_exc: 17.92 / 100,
        },
        {
            lim_inf: 10298.36,
            lim_sup: 20770.29,
            couta_fija: 1090.62,
            sobre_exc: 21.36 / 100,
        },
        {
            lim_inf: 20770.3,
            lim_sup: 32736.83,
            couta_fija: 3327.42,
            sobre_exc: 23.52 / 100,
        },
        {
            lim_inf: 32736.84,
            lim_sup: 100000.0,
            couta_fija: 6141.95,
            sobre_exc: 30.0 / 100,
        },
    ];

    // Art 113

    const tarifasOrdernadas = tarifas
        .map((item) => item.lim_inf)
        .sort((a, b) => a - b);

    let ultimo = 0;
    for (let index = 0; index < tarifasOrdernadas.length; index++) {
        const element = tarifasOrdernadas[index];

        if (element > G30) {
            ultimo = tarifasOrdernadas[index - 1];
            break;
        } else {
            ultimo = element;
        }
    }

    let tarifa = tarifas.find((item) => item.lim_inf === ultimo);

    let H30 =
        (G30 - tarifa.lim_inf) * parseFloat(tarifa.sobre_exc) +
        parseFloat(tarifa.couta_fija);
    // console.log("Art 113: ", H30);

    // 8vo. Trans
    const tarifas_8vo = [
        {
            limite_inf: 0.01,
            limite_sup: 1768.96,
            credito: 407.02,
        },
        {
            limite_inf: 1768.97,
            limite_sup: 2653.38,
            credito: 406.83,
        },
        {
            limite_inf: 2653.39,
            limite_sup: 3472.84,
            credito: 406.62,
        },
        {
            limite_inf: 3472.85,
            limite_sup: 3537.87,
            credito: 392.77,
        },
        {
            limite_inf: 3537.88,
            limite_sup: 4446.15,
            credito: 382.46,
        },
        {
            limite_inf: 4446.16,
            limite_sup: 4717.18,
            credito: 354.23,
        },
        {
            limite_inf: 4717.19,
            limite_sup: 5335.42,
            credito: 324.87,
        },
        {
            limite_inf: 5335.43,
            limite_sup: 6224.67,
            credito: 294.63,
        },
        {
            limite_inf: 6224.68,
            limite_sup: 7113.9,
            credito: 253.54,
        },
        {
            limite_inf: 7113.91,
            limite_sup: 7382.33,
            credito: 217.61,
        },
        {
            limite_inf: 7382.34,
            limite_sup: 999999.99,
            credito: 0,
        },
    ];

    const tarifas_8vo_order = tarifas_8vo
        .map((tarifa) => tarifa.limite_inf)
        .sort((a, b) => a - b);

    let ultimo_8vo = 0;
    for (let index = 0; index < tarifas_8vo_order.length; index++) {
        const element = tarifas_8vo_order[index];

        if (element > G30) {
            ultimo_8vo = tarifas_8vo_order[index - 1];
            break;
        } else {
            ultimo_8vo = element;
        }
    }

    // console.log(ultimo_8vo, "ult 8vo");

    let tarifa_8vo = tarifas_8vo.find(
        (tarifa) => tarifa.limite_inf == ultimo_8vo
    );

    let I30 = tarifa_8vo.credito;

    // console.log("8vo. Trans", I30);

    // I.S.R
    const J30 = 0;
    let K30 = H30 - J30;
    // console.log("I.S.R", K30);

    // CALCULO DE PAGOS Y RETENCIONES POR  LIQUIDACION
    const pago_liquidacion = [
        {
            opcion_d: 1,
            opcion_a: 1,
            meses_3: 90,
            dias_20: 20,
            dias_12: 12,
        },
        {
            opcion_d: 2,
            opcion_a: 2,
            meses_3: 90,
            dias_20: 0,
            dias_12: 12,
        },
        {
            opcion_d: 3,
            opcion_a: 3,
            meses_3: 0,
            dias_20: 0,
            dias_12: 12,
        },
        {
            opcion_d: 4,
            opcion_a: 4,
            meses_3: 0,
            dias_20: 0,
            dias_12: 12,
        },
        {
            opcion_d: 5,
            opcion_a: 5,
            meses_3: 0,
            dias_20: 0,
            dias_12: 0,
        },
        {
            opcion_d: 6,
            opcion_a: 6,
            meses_3: 90,
            dias_20: 0,
            dias_12: 12,
        },
        {
            opcion_d: 7,
            opcion_a: 7,
            meses_3: 0,
            dias_20: 0,
            dias_12: 12,
        },
        {
            opcion_d: 8,
            opcion_a: 8,
            meses_3: 0,
            dias_20: 0,
            dias_12: 0,
        },
        {
            opcion_d: 9,
            opcion_a: 9,
            meses_3: 0.5,
            dias_20: 0,
            dias_12: 12,
        },
        {
            opcion_d: 10,
            opcion_a: 10,
            meses_3: 180,
            dias_20: 20,
            dias_12: 12,
        },
    ];

    // console.log(
    // "******************* CALCULO DE PAGOS Y RETENCIONES POR  LIQUIDACION *****************"
    // );
    // Cálculo de la liquidación
    let C34 = DATOS_A27 === 10 ? "6 meses del salario" : "3 meses del Salario:";
    // console.log(C34);

    let C35 = "20 días por año por no reubicar";
    // console.log(C35);

    const parametro_A6 = 6;
    // 6 o 7
    let C36 =
        parametro_A6 === 1
            ? "14 o 16 días prima antigüedad:"
            : "12 días de prima antigüedad:";

    // console.log(C36);

    let C37 = DATOS_D56;
    // console.log(C37);

    // día

    let E34 = pago_liquidacion.find((item) => item.opcion_d == DATOS_A27);

    E34 = E34.meses_3;
    // console.log(`${C34} - dia:`, E34);

    let E35 = pago_liquidacion.find((item) => item.opcion_d == DATOS_A27);
    E35 = (E35.dias_20 * (F14 / 365)).toFixed(2);

    // console.log(`${C35} - dia`, E35);

    let E36 = pago_liquidacion.find((item) => item.opcion_d == DATOS_A27);
    E36 = (E36.dias_12 * (F14 / 365)).toFixed(2);
    // console.log(`${C36} - dia`, E36);

    let E37 = 0;
    // console.log(`${C37} - dia`, E37);

    // Importe
    let DATOS_B39 = type_de_pago;
    let F34 = DATOS_B39 == 2 ? DATOS_E22 : DATOS_K34 * E34;

    // console.log("1", F34);

    let F35 = DATOS_B39 == 2 ? DATOS_G22 : DATOS_K34 * E35;

    // console.log("2", F35);

    // let F36; =
    let F36 = 0;
    if (DATOS_B39 == 2) {
        F36 = DATOS_I22;
    } else {
        if (parametro_A6 == 1) {
            F36 = G3;
        } else {
            if (DATOS_K36 > F19 * 2) {
                F36 = F19 * 2;
            } else {
                F36 = DATOS_K36;
            }
        }
        F36 = F36 * E36;
    }

    // console.log("3", F36);

    let F37 = DATOS_G56;

    // console.log("4", F37);

    // total importe
    let F39 =
        parseFloat(F34) + parseFloat(F35) + parseFloat(F36) + parseFloat(F37);
    // console.log("Total importe", F39);

    // Comparación Sueldo/Indemnización

    // Sueldo mensual
    let D42 = DATOS_E12;
    // console.log("Sueldo mensual", D42);

    // Total Indemnización
    let D43 = F39;
    // console.log("Total Indemnización", F39);

    let D44 = D42 - D43;

    let C44 = D44 > 0 ? "Sueldo Mayor" : "Sueldo Menor";

    // console.log(C44, D44);

    // exentos
    let I39 = F39 > K15 * F19 * 90 ? K15 * F19 * 90 : F39;
    // console.log("Exentos", I39);

    // Gravados
    let J39 = F39 - I39;
    // console.log("Gravados", J39);

    let G41 =
        D44 < 0
            ? "Por lo que se aplica tarifa al sueldo"
            : "Por lo que se aplica tarifa directo a Indemnización Gravada";

    // console.log(`${C44} : ${G41}`);

    // Liquid. Gravado
    let G43 = D44 > 0 ? J39 : D42;
    // console.log("Liquid. Gravado", G43);

    // Art 113

    const tarifaOrd113 = tarifas
        .map((item) => item.lim_inf)
        .sort((a, b) => a - b);

    let ultimo_113 = 0;
    for (let index = 0; index < tarifaOrd113.length; index++) {
        const element = tarifaOrd113[index];

        if (element > G43) {
            ultimo_113 = tarifaOrd113[index - 1];
            break;
        }
    }

    let tarifa_113 = tarifas.find((item) => item.lim_inf === ultimo_113);

    let H43 = 0;

    if (G43 > 0) {
        H43 =
            (G43 - tarifa_113.lim_inf) * parseFloat(tarifa_113.sobre_exc) +
            parseFloat(tarifa_113.couta_fija);
    } else {
        H43 = 0;
    }
    // console.log("Art 113", H43);

    // I.S.R
    const J43 = 0;
    let K43 = H43 - J43;

    // console.log("I.S.R", K43);

    let G45 =
        D44 < 0 ? "Por lo que se calcula la tasa" : "Y no se calcula la tasa";
    // console.log(`${C44} : ${G45}`);

    // I.S.R
    let G47 = D44 < 0 ? K43 : "";
    // console.log("I.S.R", G47);
    // Sueldo
    let H47 = D44 < 0 ? D42 : "";
    // console.log("Sueldo", H47);

    let I47 = 0;

    // Tasa (%)

    if (D44 > 0) {
        I47 = "";
    } else {
        // EL ERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRORRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR
        if (G47 / H47 == "Infinity") {
            I47 = "";
        } else {
            I47 = G47 / H47;
        }
    }
    I47 = I47;
    // console.log("Tasa (%)", parseFloat(I47 * 100) + " %");

    // Determinación del ISR por la Liquidación
    let G50 =
        D44 > 0 ? "Tarifa 113 a Indem. Gravada" : "Total Indemnización Gravada";

    let J50 = D44 > 0 ? K43 : J39;

    // console.log(G50 + ":", J50);

    let G51 = D44 > 0 ? "" : "Tasa";
    let J51 = I47 < 0 ? 0 : I47;
    J51 = J51 * 100;
    // console.log(G51 + ":", J51 + "%");

    // Total Retención ISR por Indemnización

    let J52 = 0;
    if (D44 < 0) {
        // console.log("1 j52------------------");
        J52 = J50 * (J51 / 100);
    } else {
        // console.log("2 j52------------------");
        if (I47 < 0) {
            J52 = 0;
        } else {
            // console.log("33 j52------------------");
            J52 = J50;
        }
    }

    // console.log("Total Retención ISR por Indemnización", J52);

    // Percepciones Gravadas y Exentas

    // Total Cálculo finiquito
    // Importe
    let F55 = F26;
    // console.log("Total Cálculo finiquito - importe", F55);
    // Exentos
    let G55 = I26;
    // console.log("Total Cálculo finiquito - exentos", G55);
    // Gravados
    let H55 = J26;
    // console.log("Total Cálculo finiquito - gravados", H55);

    // Total Cálculo Liquidación
    // Importe
    let F56 = F39;
    // console.log("Total Cálculo Liquidación - importe", F56);
    // Exentos
    let G56 = I39;
    // console.log("Total Cálculo Liquidación - exentos", G56);
    // Gravados
    let H56 = J39;
    // console.log("Total Cálculo Liquidación - gravados", H56);

    // total importes
    let F57 = parseFloat(F55) + parseFloat(F56);
    // console.log("total importes", F57);
    // total Exentos
    let G57 = parseFloat(G56) + parseFloat(G55);
    // console.log("Total exentos", G57);
    // total Gravados

    let H57 = parseFloat(H56) + parseFloat(H55);
    // console.log("Total gravados", H57);

    // // ------------------------------------------------
    // // Resúmen del cálculo
    // console.log("*************** Resúmen del cálculo **************");
    // Total del Finiquito
    let F6 = F26;
    // console.log("Total del Finiquito", F6);
    // ISR del Finiquito
    let F7 = K30;
    // console.log("ISR del Finiquito", F7);
    // el resultado es
    let F8 = F6 - F7;
    // console.log(F8);

    // Total Liquidación
    let K6 = F39;
    // console.log("Total Liquidación", K6);
    // ISR de Liquidación

    let K7 = D44 > 0 ? K43 : J52;
    // console.log("ISR de Liquidación", K7);
    // el resultado es
    let K8 = K6 - K7;
    // console.log(K8);

    //  Retención IMSS
    let F9 = 0;

    if (
        K3 <
        Math.floor(difference(H13, new Date(`1997-07-02`)) / 365 + 15) * K19
    ) {
        if (E21 * K3 < K19 * 3 * E21) {
            F9 = E21 * K3 * 0.02375;
        } else {
            F9 =
                ((E21 * K3 - K19 * 3 * E21) * (2 - (DATOS_B32 - 1998) * 0.16)) / 100 +
                E21 * K3 * 0.02375;
        }
    } else {
        if (
            K3 <
            Math.floor(difference(H13, new Date(`1997-07-02`)) / 365 + 15) * K19 &&
            K3 < K19 * 25
        ) {
            F9 =
                ((E21 * K3 - K19 * 3 * E21) * (2 - (DATOS_B32 - 1998) * 0.16)) / 100 +
                E21 *
                K19 *
                Math.floor(difference(H13, new Date(`1997-07-02`)) / 365 + 15) *
                0.0175 +
                E21 * K3 * 0.00625;
        } else {
            F9 =
                ((E21 * K19 * 25 - K19 * 3 * E21) * (2 - (DATOS_B32 - 1998) * 0.16)) /
                100 +
                E21 *
                K19 *
                (Math.floor(difference(H13, new Date(`1997-07-02`)) / 365) + 15) *
                0.0175 +
                E21 * K19 * 25 * 0.00625;
        }
    }

    // console.log("Retención IMSS", F9);

    let G9 = F8 + K8 - F9;
    // console.log("Neto a Recibir:", G9);

    // console.log("Salarios Pendientes");
    // console.log("Días", E21);
    let PROY_F16 = F21;
    // console.log("Cantidad", PROY_F16);
    // console.log("-----------------------------");
    // console.log("AGUINALDO PROPORCIONAL");
    // console.log("Días", E23);
    let PROY_F17 = F23;
    // console.log("Cantidad", PROY_F17);
    // console.log("-----------------------------");
    // console.log(E22);
    // console.log("VACACIONES PROPORCIONALES");
    // console.log("Días", E22);
    let PROY_E18 = E22;
    let PROY_F18 = PROY_E18 * G3;
    // console.log("Cantidad", PROY_F18);
    // console.log("-----------------------------");
    // console.log("PRIMA VACACIONAL PROPORCIONAL");
    // console.log("Días", "25%");

    // console.log(PROY_F18);
    let PROY_F19 = PROY_F18 / 4;
    // console.log("Cantidad", PROY_F19);
    // console.log("-----------------------------");
    // console.log("GRATIFICACIONES");
    let PROY_E20 = 0;
    // console.log("Días", "-");
    let PROY_20 = PROY_E20 * G3;
    // console.log("Cantidad", PROY_20);
    // console.log("-----------------------------");

    // TOTAL PRESTACIONES DEVENGADAS:
    let PROY_F21 =
        parseFloat(PROY_F16) +
        parseFloat(PROY_F17) +
        parseFloat(PROY_F18) +
        parseFloat(PROY_F19) +
        parseFloat(PROY_20);

    // console.log("TOTAL PRESTACIONES DEVENGADAS:", PROY_F21);
    // console.log("********************************************************");
    let PROY_22 = 0;
    // console.log("DEDUCCIONES", PROY_22);
    // // GRAN TOTAL:
    let PROY_23 = PROY_F21 - PROY_22;
    // console.log("GRAN TOTAL", PROY_23.toFixed(2));
    // let cambio_peso = 20;

    const date_entrada_format = `${DATOS_A28}/${DATOS_A29}/${DATOS_A32}`;
    const date_salida_format = `${DATOS_B28}/${DATOS_B29}/${DATOS_B32}`;

    //   OBTENIENDO DATOS PARA LA PROYECCIÓN
    const formatterDolar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    });

    // const formatterPeso = new Intl.NumberFormat("es-CO", {
    //   style: "currency",
    //   currency: "MXN",
    //   minimumFractionDigits: 0,
    // });

    //   calulo de informe
    // DATOS GENERALES

    // const REC_C1 = DATOS_F8;

    const tipos_despidos = [
        {id: 1, title: "DESPIDO"},
        {id: 2, title: "DESPIDO"},
        {id: 3, title: "DESPIDO JUSTIFICADO"},
        {id: 4, title: "RENUNCIA VOLUNTARIA"},
        {id: 5, title: "RENUNCIA VOLUNTARIA"},
        {id: 6, title: "PENDIENTE"},
        {id: 7, title: "PENDIENTE"},
        {id: 8, title: "PENDIENTE"},
        {id: 9, title: "PENDIENTE"},
        {id: 10, title: "PENDIENTE"},
    ];

    let REC_F3 = tipos_despidos.find((item) => item.id == DATOS_A27);
    // const REC_C7 = `RECIBÍ DE ${REC_C1} LA CANTIDAD DE: `;

    let REC_F18 = DATOS_F10;
    // let REC_F19 = DATOS_H12;
    let REC_F20 = DATOS_E12;
    // let REC_F21 = DATOS_K12;
    let REC_F22 = date_entrada_format;
    let REC_F23 = date_salida_format;

    let REC_G28 = E21;
    let REC_F30 = DATOS_B32;
    let REC_G30 = E23;
    let REC_G33 = DATOS_I48;
    let REC_G34 = DATOS_I50;
    let REC_G31 = E22 - REC_G33;
    let REC_G32 = E24 - REC_G34;
    let REC_G35 = "N/A";

    let REC_F32;

    if (DATOS_B32 == DATOS_A32) {
        REC_F32 = `${DATOS_B32} - ${Number(DATOS_B32) + 1}`;
    } else {
        REC_F32 = `${Number(DATOS_B32) - 1} - ${DATOS_B32}`;
    }

    let REC_H28 = F21;
    let REC_H30 = F23;
    let REC_H31 = REC_G31 * G3;
    let REC_H32 = REC_G32 * G3 * K13;
    let REC_H33 = REC_G33 * G3;
    let REC_H34 = REC_G34 * G3 * K13;
    let REC_H35 = Number(F25);
    let REC_H37 =
        parseFloat(REC_H28) +
        parseFloat(REC_H30) +
        parseFloat(REC_H31) +
        parseFloat(REC_H32) +
        parseFloat(REC_H33) +
        parseFloat(REC_H34) +
        parseFloat(REC_H35);

    // INDEMINZACIONES (LIQUIDACIÓN)
    let REC_C40 = C34;
    let REC_C41 = C35;
    let REC_C42 = C36;
    let REC_C43 = C37;

    let REC_G40 = Number(E34);
    let REC_G41 = Number(E35);
    let REC_G42 = Number(E36);

    let REC_H40 = Number(F34);
    let REC_H41 = Number(F35);
    let REC_H42 = Number(F36);
    let REC_H43 = Number(F37);

    // TOTAL INDEMNIZACIÓN:
    let REC_H45 =
        parseFloat(REC_H40) +
        parseFloat(REC_H41) +
        parseFloat(REC_H42) +
        parseFloat(REC_H43);

    // TOTAL PERCEPCIONES:
    let REC_H47 = parseFloat(REC_H45) + parseFloat(REC_H37);

    // DEDUCCIONES
    // console.log(K7);
    // console.log(F7);
    let REC_H51 = parseFloat(K7) + parseFloat(F7);
    let REC_C51 = REC_H51 < 0 ? "SUBSIDIO AL EMPLEO" : "I.S.R. RETENIDO";
    let REC_H52 = F9;
    let REC_H53 = 0;
    let REC_H54 = 0;
    let REC_H55 = 0;
    let REC_H56 = 0;
    let REC_H57 = 0;
    let REC_H58 =
        parseFloat(REC_H51) +
        parseFloat(REC_H52) +
        parseFloat(REC_H53) +
        parseFloat(REC_H54) +
        parseFloat(REC_H55) +
        parseFloat(REC_H56) +
        parseFloat(REC_H57);

    // NETO A RECIBIR
    let REC_H61 = REC_H47 - REC_H58;

    let REC_H5 = REC_H61;
    const proyeccion = `
  <section class="img-backgroundp-size"   > 
  <img src="https://enfoquelaboral.mx/img/home1.png"  alt="">
         
          <div class="col-sm-1">
        
        </div>
           <div class="col-sm-11 col-md-auto">
        <h3 class="h6"><strong>NOMBRE:</strong> ${DATOS_F10}</h3>
        <h3 class="h6"><strong>FECHA INGRESO:</strong> ${date_entrada_format}</h3>
        <h3 class="h6"><strong>FECHA BAJA:</strong> ${date_salida_format}</h3>
        <h3 class="h6"><strong>ANTIGÜEDAD:</strong> ${F14 + " Dí­as"}</h3>
        <h3 class="h6">SUELDO DIARIO: ${DATOS_K36.toFixed(2)}</h3>
        </div>
            
        </section>
        <div class="col-sm-1">
        
        </div>
        <div class="col-sm-10">
            <h3 class="text-center" style="color: #FAB848"><strong>PRESTACIONES</strong></h3>
            <div class="">
            <table class="table table-bordered table-sm table-responsive" style="border-top: 1px solid #4867AE;border-left: 1px solid #4867AE;border-right: 1px solid #4867AE;border-bottom: 1px solid #4867AE">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th class="text-center" scope="col">DÍAS</th>
                    <th class="text-center" scope="col">CANTIDAD</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>SALARIOS PENDIENTES</td>
                    <td class="text-center">${E21}</td>
                    <td class="text-end">${formatterDolar.format(
        PROY_F16.toFixed(2)
    )}</td>
                </tr>
                <tr>
                    <td>AGUINALDO PROPORCIONAL</td>
                    <td class="text-center">${E23.toFixed(2)}</td>
                    <td class="text-end">${formatterDolar.format(PROY_F17)}</td>
                </tr>
                <tr>
                    <td>VACACIONES PROPORCIONALES</td>
                    <td class="text-center">${E22.toFixed(2)}</td>
                    <td class="text-end">${formatterDolar.format(PROY_F18)}</td>
                </tr>
                <tr>
                    <td>PRIMA VACACIONAL PROPORCIONAL</td>
                    <td class="text-center">${K13 * 100} %</td>
                    <td class="text-end">${formatterDolar.format(PROY_F19)}</td>
                </tr>
                <tr>
                    <td >GRATIFICACIONES</td>
                    <td></td>
                    <td class="text-end">${formatterDolar.format(PROY_20)}</td>
                </tr>
                <tr>
                    <td colspan="2" class="text-end">TOTAL PRESTACIONES DEVENGADAS:</td>
                    <td class="text-end">${formatterDolar.format(PROY_F21)}</td>
                </tr>
                <tr >
                    <td colspan="2">DEDUCCIONES</td>
                    <td class="text-end">${formatterDolar.format(PROY_22)}</td>
                </tr>
                <tr>
                    <td colspan="2" class="text-end">GRAN TOTAL:</td>
                    <td class="text-end">${formatterDolar.format(PROY_23)}</td>
                </tr>

            </tbody>
            </table>
        </div>
          <div class="col-sm-1">
        
        </div>
        <div class="col-lg-12" style="margin-top:-19px">
                    <h4 class="text-center st-title black" style="color: #FAB848">RECIBO DE LIQUIDACIÓN Y/O FINIQUITO POR</h4>
            <h4 class="text-center  text-center text-decoration-underline" style="color: #4867AE"> <strong>Renuncia Voluntaria</strong></h4>
            <p class="text-center" style="color: #4867AE">BUENO POR:</p>
            
            <p style="font-size: 12px;text-align: justify">Por concepto de pago de finiquito de las prestaciones laborales que a continuación se enumeran, con motivo de la terminación de la relación de trabajo existente exclusivamente con esta empresa.
            <br>
            Al recibo de estas cantidades, me doy por cubierto de todas y cada una de las prestaciones que de hecho y por derecho pudieran corresponderme, por lo que otorgo a favor de la empresa el más amplio finiquito que en derecho proceda, sin reservarme acción alguna en contra de esta empresa, en virtud de que a la firma de este finiquito no se me queda a deber cantidad alguna por ningún concepto.
            </p>
</div>

        </div>
        </section>`;
    const recibo = `
  <img src="https://enfoquelaboral.mx/img/home2.png" style="margin-bottom: -20px"  alt="">
    <div class="col-md-12 " >
      <div class="col-sm-12">
     
                     </div>

            <div class="">
                <h4 style="color: #4867AE;text-align: center"><strong>DATOS GENERALES</strong></h4>
                <div class="row justify-content-center">
                   <div class="col-sm-2">
        
                     </div>
                    <div class="col-md-8">

                        <table class="table table-bordered table-responsive table-sm" style="border-top: 1px solid #4867AE;border-left: 1px solid #4867AE;border-right: 1px solid #4867AE;border-bottom: 1px solid #4867AE">
                            <tr>
                                <th style="color: #4867AE">NOMBRE:</th>
                                <td>${REC_F18}</td>
                            </tr>
                            
                            <tr>
                                <th style="color: #4867AE">SUELDO MENSUAL:</th>
                                <td>${formatterDolar.format(
        salario_mensual
    )}</td>
                            </tr>
                        
                            <tr>
                                <th>FINIQUITO Y LIQUIDACIÓN AL:</th>
                                <td>${REC_F22}</td>
                            </tr>
                            <tr>
                                <th>FECHA DE INGRESO:</th>
                                <td>${REC_F23}</td>
                            </tr>
                        </table>
                    </div>
                     <div class="col-sm-2">
        
                     </div>
                </div>
            </div>
            <div class="">
                <h4 class="" style="color: #4867AE;text-align: center"><strong>PERCEPCIONES</strong></h4>
                <h5 class="h6 text-decoration-underline" style="text-align: center">PRESTACIONES DEVENGADAS NO COBRADAS (FINIQUITO)</h5>
                <div class="row justify-content-center table-sm table-responsive">
                   <div class="col-sm-1">
        
                     </div>
                    <div class="col-md-10">

                        <table class="table table-bordered" style="border-top: 1px solid #4867AE;border-left: 1px solid #4867AE;border-right: 1px solid #4867AE;border-bottom: 1px solid #4867AE">
                            <tr>
                                <th></th>
                                <td>PERIODO</td>
                                <td>DIARIAS</td>
                                <th></th>
                            </tr>
                            <tr>
                                <th>SALARIOS PENDIENTES</th>
                                <td></td>
                                <td class="text-center">${REC_G28}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H28
    )}</td>
                            </tr>
                            <tr>
                                <th>COMISIONES</th>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>AGUINALDO PROPORCIONAL</th>
                                <td>${REC_F30}</td>
                                <td class="text-center">${REC_G30.toFixed(
        2
    )}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H30.toFixed(2)
    )}</td>
                            </tr>
                            <tr>
                                <th>VACACIONES PROPORCIONALES</th>
                                <td>${REC_F32}</td>
                                <td class="text-center">${REC_G31.toFixed(
        2
    )}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H31.toFixed(2)
    )}</td>
                            </tr>
                            <tr>
                                <th>PRIMA VACACIONAL PROPORCIONAL</th>
                                <td>${REC_F32}</td>
                                <td class="text-center">${REC_G32.toFixed(
        2
    )}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H32.toFixed(2)
    )}</td>
                            </tr>
                            <tr>
                                <th>VACACIONES PENDIENTES</th>
                                <td>${DATOS_I48 != 0
        ? `${DATOS_K48} - ${DATOS_K58}`
        : ""
    }</td>
                                <td class="text-center">${REC_G33}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H33
    )}</td>
                            </tr>
                            <tr>
                                <th>PRIMA VACACIONAL PENDIENTE</th>
                                <td>${DATOS_I50 != 0
        ? `${DATOS_K50} - ${DATOS_K60}`
        : ""
    }</td>
                                <td class="text-center">${REC_G34}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H34
    )}</td>
                            </tr>
                            <tr>
                                <th>OTRAS PRESTACIONES EXTRAS</th>
                                <td></td>
                                <td class="text-center">${REC_G35}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H35
    )}</td>

                            </tr>
                            <tr>
                                <th colspan="3" class="text-end">TOTAL PRESTACIONES DEVENGADAS</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H37
    )}</td>

                            </tr>
                        </table>
                    </div>
                     <div class="col-sm-1">
        
                     </div>
                </div>
            </div>
            <div class="">
                <h4 class="" style="color: #4867AE; text-align: center"><strong>INDEMINZACIONES</strong> (LIQUIDACIÓN)</h4>
                <div class="row justify-content-center table-sm table-responsive">
                     <div class="col-sm-1">
        
                     </div>
                    <div class="col-md-10">

                        <table class="table table-bordered" style="border-top: 1px solid #4867AE;border-left: 1px solid #4867AE;border-right: 1px solid #4867AE;border-bottom: 1px solid #4867AE">

                            <tr>
                                <th>${REC_C40}</th>
                                <td class="text-end">${REC_G40}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H40
    )}</td>
                            </tr>
                            <tr>
                                <th>${REC_C41}</th>
                                <td class="text-end">${REC_G41}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H41
    )}</td>
                            </tr>
                            <tr>
                                <th>${REC_C42}</th>
                                <td class="text-end">${REC_G42}</td>
                                <td class="text-end">${formatterDolar.format(
        REC_H42
    )}</td>
                            </tr>
                            <tr>
                                <th>${REC_C43}</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H43
    )}</td>
                                <td></td>
                            </tr>

                            <tr>
                                <th colspan="2" class="text-end">TOTAL INDEMNIZACIÓN:</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H45
    )}</td>
                            </tr>
                            <tr>
                                <th colspan="2" class="text-end">TOTAL PERCEPCIONES</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H47
    )}</td>
                            </tr>
                        </table>
                    </div>
                           <div class="col-sm-1">
        
                     </div>
                </div>
            </div>
            <div class="">
                <h4 class="" style="color: #4867AE;text-align: center"> <strong>DEDUCCIONES</strong></h4>
                <div class="row justify-content-center table-sm table-responsive">
                          <div class="col-sm-1">
        
                     </div>
                    <div class="col-md-10">

                        <table class="table table-bordered" style="border-top: 1px solid #4867AE;border-left: 1px solid #4867AE;border-right: 1px solid #4867AE;border-bottom: 1px solid #4867AE">

                            <tr>
                                <th>${REC_C51}</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H51
    )}</td>
                            </tr>
                            <tr>
                                <th>I.M.S.S. RETENIDO</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H52
    )}</td>
                            </tr>
                            <tr>
                                <th>DESCUENTO UNIFORMES</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H53
    )}</td>
                            </tr>
                            <tr>
                                <th>DESCUENTO POLIZA DE AUTOMOVIL</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H54
    )}</td>
                            </tr>
                            <tr>
                                <th>PENSION ALIMENTICIA</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H55
    )}</td>
                            </tr>
                            <tr>
                                <th>CAJA DE AHORRO</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H56
    )}</td>
                            </tr>
                            <tr>
                                <th>CREDITO INFONAVIT</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H57
    )}</td>
                            </tr>

                            <tr>
                                <th class="text-end">TOTAL DEDUCCIONES</th>
                                <td class="text-end">${formatterDolar.format(
        REC_H58
    )}</td>
                            </tr>
                        </table>
                    </div>
                   <div class="col-sm-1">
        
                     </div>
                </div>
            </div>
               
                    <div class="col-xs-6 text-left">
                        <p>NETO A RECIBIR</p>
                    </div>
                    <div class="col-xs-6 text-right">
                        <h5 class="text-decoration-underline text-h4">${formatterDolar.format(
        REC_H61
    )}</h5>
                            <h4 class="text-uppercase">${REC_F18}</h4>
                    </div>
        
    </div>
</div>`;
    return {proyeccion, recibo};
};
