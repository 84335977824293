//Required package
// var { jsPDF } = require("jspdf");
var html2pdf = require("html2pdf.js");


export const openFile = (contentHtml) => {
    // console.log(this.finiquito.recibo);
    // var worker = html2pdf().from(`<h1>hi</h1>`).save();
    console.log(contentHtml)
    var element = `<did>
        ${contentHtml.proyeccion}
        <br />
         ${contentHtml.recibo}

    </did>`;
    var opt = {
        margin: 0,
        filename: "finiquito.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1,dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // New Promise-based usage:
    html2pdf().from(element).set(opt).save();

    // Old monolithic-style usage:
    // html2pdf(element, opt);

    //       html2pdf()
    //         .set({
    //           margin: 1,
    //           filename: "documento.pdf",
    //           image: {
    //             type: "jpeg",
    //             quality: 0.98,
    //           },
    //           html2canvas: {
    //             scale: 3, // A mayor escala, mejores gráficos, pero más peso
    //             letterRendering: true,
    //           },
    //           jsPDF: {
    //             unit: "in",
    //             format: "a3",
    //             orientation: "portrait", // landscape o portrait
    //           },
    //         })
    //         .from(contenidoHtml)
    //         .save()
    //         .catch((err) => console.log(err));

}
