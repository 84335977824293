<template>
  <div >


    <section class="container">
      <div class="row">
        <div class="col-xs-12 pt-4">
          <h2 class="st-title black">Calculadora de <u>Finiquito</u></h2>
        </div>
      </div>
      <div class="row pt-4">
        <form
            @submit.prevent="send"
            ref="form"
            class="col-md-12"
            style="position: relative"
        >
          <ul class="stepper linear">
            <li class="step active">
              <div class="step-title waves-effect waves-dark">
                ¿Cuál es tu nombre completo?
              </div>
              <div class="step-content">
                <div class="row">
                  <div class="col-xs-12 px-0 col-md-6">
                    <div class="form-group">
                      <input
                          type="text"
                          class="form-control validate"
                          id="name"
                          ref="name"
                          v-model.trim="form.name"
                          required
                      />
                    </div>
                  </div>
                </div>
                <div class="step-actions">
                  <button
                      @click="validate_name"
                      type="submit"
                      class="waves-effect waves-dark next-step btn calcular"
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </li>

            <li class="step">
              <div class="step-title waves-effect waves-dark">
                ¿Cuál fue tu último salario mensual?
              </div>
              <div class="step-content">
                <div class="row">
                  <div class="col-xs-12 px-0 col-md-6">
                    <div class="form-group">
                      <input
                          type="number"
                          step="any"
                          ref="salario_mensual"
                          v-model.number="form.salario_mensual"
                          class="form-control validate"
                          min="1"
                          required
                      />
                    </div>
                  </div>
                </div>
                <div class="step-actions">
                  <button
                      @click="validate_salario_mensual"
                      class="waves-effect waves-dark btn next-step calcular"
                  >
                    CONTINUAR
                  </button>
                  <button
                      class="waves-effect waves-dark btn-flat previous-step btn"
                  >
                    REGRESAR
                  </button>
                </div>
              </div>
            </li>
            <li class="step">
              <div class="step-title waves-effect waves-dark">
                ¿Cuál es el motivo por el cual termina tu relación laboral?
              </div>
              <div class="step-content">
                <div class="row">
                  <div class="col-xs-12 px-0 col-md-6">
                    <div class="form-group">
                      <select
                          v-model="form.motivo"
                          ref="motivo"
                          class="form-control validate"
                          id="motivo"
                          required
                      >
                        <option value="" selected>
                          -- Seleccione un motivo --
                        </option>
                        <option value="2">Despido</option>
                        <option value="4">Renuncia con más de 15 años</option>
                        <option value="5">Renuncia con menos de 15 años</option>
                        <option value="8">Terminación de contrato</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="step-actions">
                  <button
                      @click="validate_motivo"
                      class="waves-effect waves-dark btn next-step calcular"
                  >
                    CONTINUAR
                  </button>
                  <button
                      class="waves-effect waves-dark btn-flat previous-step btn"
                  >
                    REGRESAR
                  </button>
                </div>
              </div>
            </li>
            <li class="step">
              <div class="step-title waves-effect waves-dark">
                ¿Cuál fue tu fecha de ingreso y de salida?
              </div>
              <div class="step-content">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label for="date_ingreso" class=""
                    >¿Cuál fue tu fecha de ingreso?</label
                    >
                    <input
                        v-model.trim="form.date_ingreso"
                        ref="date_ingreso"
                        type="date"
                        onkeydown="return false"
                        class="form-control"
                        name="date_ingreso"
                        id="date_ingreso"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="date_egreso" class=""
                    >¿Cuál fue tu fecha de salida?</label
                    >
                    <input
                        type="date"
                        ref="date_egreso"
                        v-model.trim="form.date_egreso"
                        onkeydown="return false"
                        class="form-control"
                        id="date_egreso"
                    />
                  </div>
                </div>
                <div class="step-actions">
                  <button
                      @click="validate_dates"
                      class="waves-effect waves-dark btn calcular"
                  >
                    CONTINUAR
                  </button>
                  <button
                      class="waves-effect waves-dark btn-flat previous-step btn"
                  >
                    REGRESAR
                  </button>
                </div>
              </div>
            </li>
            <li class="step">
              <div class="step-title waves-effect waves-dark">
                ¿En que estado de la república te encuentras?
              </div>
              <div class="step-content">
                <div class="row">
                  <div class="col-xs-12 px-0 col-md-6">
                    <div class="form-group">
                      <select
                          ref="state"
                          v-model="form.state"
                          class="form-control validate"
                          id="state"
                          required
                      >
                        <option value="">-- Seleccione un estado --</option>
                        <option value="Aguascalientes">Aguascalientes</option>
                        <option value="Baja California">Baja California</option>
                        <option value="Baja California Sur">Baja California Sur</option>
                        <option value="Campeche">Campeche</option>
                        <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
                        <option value="Colima">Colima</option>
                        <option value="Chiapas">Chiapas</option>
                        <option value="Chihuahua">Chihuahua</option>
                        <option value="Ciudad de México">Ciudad de México</option>
                        <option value="Durango">Durango</option>
                        <option value="Guanajuato">Guanajuato</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Hidalgo">Hidalgo</option>
                        <option value="Jalisco" selected>Jalisco</option>
                        <option value="México">México</option>
                        <option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
                        <option value="Morelos">Morelos</option>
                        <option value="Nayarit">Nayarit</option>
                        <option value="Nuevo León">Nuevo León</option>
                        <option value="Oaxaca">Oaxaca</option>
                        <option value="Puebla">Puebla</option>
                        <option value="Querétaro">Querétaro</option>
                        <option value="Quintana Roo">Quintana Roo</option>
                        <option value="San Luis Potosí">San Luis Potosí</option>
                        <option value="Sinaloa">Sinaloa</option>
                        <option value="Sonora">Sonora</option>
                        <option value="Tabasco">Tabasco</option>
                        <option value="Tamaulipas">Tamaulipas</option>
                        <option value="Tlaxcala">Tlaxcala</option>
                        <option value="Veracruz de Ignacio de la Llave">Veracruz de Ignacio de la Llave</option>
                        <option value="Yucatán">Yucatán</option>
                        <option value="Zacatecas">Zacatecas</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="step-actions">
                  <button
                      @click="validate_state"
                      class="waves-effect waves-dark btn next-step calcular"
                      id="validate-state"
                  >
                    CONTINUAR
                  </button>
                  <button
                      class="waves-effect waves-dark btn-flat previous-step btn"
                  >
                    REGRESAR
                  </button>
                </div>
              </div>
            </li>

            <li class="step">
              <div class="step-title waves-effect waves-dark">
                ¿Cuantos días de vacaciones y prima vacacional te adeudan?
              </div>
              <div class="step-content">
                <div class="row">
                  <div class="col-xs-12 col-md-4 pt-0">
                    <div class="form-group">
                      <label for="dias_vacaciones" class=""
                      >Días de vacaciones pendientes por pagar:</label
                      >
                      <input
                          type="number"
                          id="dias_vacaciones"
                          class="form-control"
                          ref="dias_vacaciones"
                          v-model.number="form.dias_vacaciones"
                          min="0"
                      />
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-8 pt-0" v-show="show_peridos_v">
                    <label
                    >¿A cuál periodo corresponden esos días de vacaciones
                      pendientes por pagar?</label
                    >
                    <div class="row">
                      <div class="col-xs-12 col-md-6">
                        <select
                            id="perido_inicio_v"
                            ref="perido_inicio_v"
                            class="form-control validate"
                            v-model.number="form.perido_inicio_v"
                        >
                          <option value="">-- Seleccione un año --</option>
                          <option
                              v-for="(year, index) in years"
                              :key="index"
                              :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                      <div class="col-xs-12 col-md-6">
                        <select
                            class="form-control validate"
                            ref="perido_final_v"
                            v-model.number="form.perido_final_v"
                        >
                          <option value="">-- Seleccione un año --</option>
                          <option
                              v-for="(year, index) in years"
                              :key="index"
                              :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-md-4 pt-0">
                    <label for="dias_prima_v" class=""
                    >Días de prima vacacional pendientes por pagar:</label
                    >
                    <input
                        v-model="form.dias_prima_v"
                        ref="dias_prima_v"
                        type="number"
                        step="any"
                        class="form-control"
                        min="0"
                    />
                  </div>
                  <div v-show="show_peridos_p_v" class="col-xs-12 col-md-8 pt-0">
                    <label for="dias_prima_v"
                    >¿A cuál periodo corresponden esos días de prima vacacional
                      pendientes por pagar?</label
                    >
                    <div class="row">
                      <div class="col-xs-12 col-md-6">
                        <select
                            ref="periodo_inicio_p_v"
                            v-model="form.periodo_inicio_p_v"
                            class="form-control"
                            name=""
                            id="periodo_inicio_p_v"
                        >
                          <option value="">-- Seleccione un año --</option>
                          <option
                              v-for="(year, index) in years"
                              :key="index"
                              :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                      <div class="col-xs-12 col-md-6">
                        <select
                            v-model="form.periodo_final_p_v"
                            class="form-control"
                            ref="periodo_final_p_v"
                            id="periodo_final_p_v"
                        >
                          <option value="">-- Seleccione un año --</option>
                          <option
                              v-for="(year, index) in years"
                              :key="index"
                              :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="step-actions">
                  <button
                      type="submit"
                      class="waves-effect waves-dark btn calcular"
                  >
                    CALCULAR
                  </button>
                  <button
                      @click="resetForm"
                      type="button"
                      class="waves-effect waves-dark btn-flat btn"
                  >
                    BORRAR
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </form>
      </div>
      <div v-if="showLoading" class="d-flex justify-center">
        <Loading/>
      </div>

    </section>
    <div v-if="finiquito && showFiniquito">
      <div class="container">
        <button @click="imprimir" type="submit" class="btn calcular my-8">
          Imprimir pdf
        </button>
      </div>

        <div  v-html="finiquito.proyeccion"></div>

      <div v-html="finiquito.recibo"></div>
      <br/>
    </div>
  </div>
</template>

<script>
import Loading from "../components/shared/Loading.vue";
import {calcalator} from "../services/calculador";
import {openFile} from "../services/pdf";

export default {
  components: {Loading},
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Calculadora Finiquito",
      };
    },
  },
  mounted() {
    let stepperDiv = document.querySelector(".stepper");
    let stepper = new MStepper(stepperDiv);
    this.stepper = stepper;
    // console.log(stepper);

    const dataLocal = JSON.parse(sessionStorage.getItem("data"));
    if (dataLocal) {
      this.form.name = dataLocal.name;
      this.form.motivo = dataLocal.motivo;
      this.form.salario_mensual = dataLocal.salario_mensual;
      this.form.date_ingreso = dataLocal.date_ingreso;
      this.form.date_egreso = dataLocal.date_egreso;

      stapList = document.getElementsByClassName("step");
      let stapList = document.getElementsByClassName("step");

      for (let i = 0; i < 4; i++) {
        stapList[i].className = stapList[i].className.replace(
            "step",
            "step done"
        );
      }
      stepper.openStep(4);
    }
  },
  data() {
    return {
      years: ["2016", "2017", "2018", "2019", "2020", "2021", "2022"],
      formDefault: {
        name: "",
        salario_mensual: 0,
        motivo: "",
        state: "",
        date_ingreso: "",
        date_egreso: "",
        dias_vacaciones: 0,
        perido_inicio_v: "",
        perido_final_v: "",
        dias_prima_v: 0,
        periodo_inicio_p_v: "",
        periodo_final_p_v: "",
      },
      form: {
        name: "",
        salario_mensual: 0,
        motivo: "",
        state: "",
        date_ingreso: "",
        date_egreso: "",
        dias_vacaciones: 0,
        perido_inicio_v: "",
        perido_final_v: "",
        dias_prima_v: 0,
        periodo_inicio_p_v: "",
        periodo_final_p_v: "",
      },
      stepper: null,
      show_peridos_v: false,
      show_peridos_p_v: false,
      finiquito: null,
      showLoading: false,
      showFiniquito: false,
    };
  },

  watch: {
    "form.dias_vacaciones": function (newVal) {
      if (newVal != 0) {
        // console.log("muestrtae");
        this.show_peridos_v = true;
      } else {
        this.show_peridos_v = false;
      }
    },
    "form.dias_prima_v": function (newVal) {
      if (newVal != 0) {
        this.show_peridos_p_v = true;
      } else {
        this.show_peridos_p_v = false;
      }
    },
  },
  methods: {
    validate_name() {
      if (this.form.name.length <= 0) {
        alert("El nombre del trabajador es requerido");
        this.$refs.name.focus();
        this.stepper.destroyFeedback(false);
        return;
      }
    },
    validate_salario_mensual() {
      if (this.form.salario_mensual <= 0) {
        alert("El ultimo sueldo mensual es requerido");
        this.$refs.salario_mensual.focus();
        this.stepper.destroyFeedback(false);
        return;
      }
    },
    validate_motivo() {
      if (this.form.motivo.length <= 0) {
        alert("El motivo es requerido");
        this.$refs.motivo.focus();
        this.stepper.destroyFeedback(false);
        return;
      }
    },
    validate_state() {
      if (this.form.state <= 0) {
        alert("El estado es requerido");
        this.$refs.state.focus();
        this.stepper.destroyFeedback(false);
        return;
      }
    },
    validate_dates() {
      if (this.form.date_ingreso.length <= 0) {
        alert("La fecha de ingreso al trabajo es requerido");
        this.$refs.date_ingreso.focus();
        this.stepper.wrongStep();
        this.stepper.destroyFeedback(false);
        return;
      }
      if (this.form.date_egreso.length <= 0) {
        alert("La fecha de salida es requerido");
        this.$refs.date_egreso.focus();
        this.stepper.wrongStep();
        this.stepper.destroyFeedback(false);
        return;
      }

      if (this.form.date_egreso < this.form.date_ingreso) {
        alert("La fecha de salida no puede ser menor a la fecha de ingreso");
        this.$refs.date_ingreso.focus();
        this.stepper.wrongStep();
        this.stepper.destroyFeedback(false);
        return;
      }
      // console.log("salir");
      this.stepper.destroyFeedback(true);
      this.stepper.nextStep();
    },
    validate_periodo_v() {
      if (this.form.dias_vacaciones != 0) {
        if (this.form.perido_inicio_v <= 0) {
          alert("El periodo de inicio de vacaciones es requerido");
          this.$refs.perido_inicio_v.focus();
          this.stepper.wrongStep();
          this.stepper.destroyFeedback(false);
          return false;
        }
        if (this.form.perido_final_v <= 0) {
          alert("El periodo final de vacaciones es requerido");
          this.$refs.perido_final_v.focus();
          this.stepper.wrongStep();
          this.stepper.destroyFeedback(false);
          return false;
        }
        if (this.form.perido_final_v < this.form.perido_inicio_v) {
          alert(
              "El periodo final de vacaciones no puede ser menor al de inicio"
          );
          this.$refs.perido_inicio_v.focus();
          this.stepper.wrongStep();
          this.stepper.destroyFeedback(false);
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    validate_periodo_prima_v() {
      if (this.form.dias_prima_v != 0) {
        if (this.form.periodo_inicio_p_v <= 0) {
          alert("El periodo de inicio de prima vacacional es requerido");
          this.$refs.periodo_inicio_p_v.focus();
          this.stepper.wrongStep();
          this.stepper.destroyFeedback(false);
          return false;
        }
        if (this.form.periodo_final_p_v <= 0) {
          alert("El periodo final de prima vacacional es requerido");
          this.$refs.periodo_final_p_v.focus();
          this.stepper.wrongStep();
          this.stepper.destroyFeedback(false);
          return false;
        }
        if (this.form.periodo_final_p_v < this.form.periodo_inicio_p_v) {
          alert(
              "El periodo final de prima vacacional no puede ser menor al de inicio"
          );
          this.$refs.periodo_inicio_p_v.focus();
          this.stepper.wrongStep();
          this.stepper.destroyFeedback(false);
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    resetForm() {
      // console.log("reset form");
      this.showFiniquito = false;
      this.showLoading = false;

      sessionStorage.removeItem("data");
      let stapList = document.getElementsByClassName("step");
      for (let i = 0; i < stapList.length; i++) {
        stapList[i].className = stapList[i].className.replace("done", "");
      }
      this.$refs.form.reset();
      this.form = Object.assign({}, this.formDefault);
      this.stepper.openStep(0);
      this.stepper.resetStepper();
    },
    send() {
      if (!this.validate_periodo_v() || !this.validate_periodo_prima_v()) {
        return;
      } else {
        this.showLoading = true;
        this.showFiniquito = false;

        setTimeout(() => {
          this.finiquito = calcalator(this.form);

          this.showFiniquito = true;
          this.showLoading = false;
        }, 2000);
        // console.log("reset form");

        sessionStorage.removeItem("data");
        let stapList = document.getElementsByClassName("step");
        for (let i = 0; i < stapList.length; i++) {
          stapList[i].className = stapList[i].className.replace("done", "");
        }
        // this.$refs.form.reset();
        // this.form = Object.assign({}, this.formDefault);
        // this.stepper.openStep(0);
      }
    },
    imprimir() {
      // console.log("imprimir");
      // console.log(this.finiquito);
      openFile(this.finiquito);
    },
  },
};
</script>

<style scoped>
ul.stepper .step .step-title:hover {
  background-color: transparent;
}

ul.stepper .step.active::before,
ul.stepper .step.done::before {
  background-color: #FAB848;
}

ul.stepper .step.wrong::before {
  background-color: red;
}

button.calcular {
  background: #FAB848 none repeat scroll 0 0;
  border: medium none;
  color: #131515;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 10px;
}

button.calcular i {
  margin-left: 5px;
}

</style>
